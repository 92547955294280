export const tableMixin = {
    data(){
        return{
            typeList:[
                {label:'全部',value:''},
                {label:'居民人员名册',value:'1'},
                {label:'网格(楼栋)党小组',value:'2'},
                {label:'网格党支部',value:'3'},
                
            ],
            table: {
                talbeColumns: [
                  {
                    key: "zoneName",
                    align: "center",
                    title: "区",
                  },
                  {
                    key: "streetName",
                    align: "center",
                    title: "街道",
                  },
                  {
                    key: "communityName",
                    align: "center",
                    title: "社区",
                  },
                  {
                    key: "name",
                    align: "center",
                    title: "表格名称",
                  },
                  {
                    key: "importUserName",
                    align: "center",
                    title: "导入人",
                  },
                  {
                    key: "gmtCreate",
                    align: "center",
                    title: "导入时间",
                    render:(h,params)=>{
                        return h ('div',{},this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd hh:mm:ss'))
                    }
                  },
                  {
                    title: "操作",
                    align: "center",
                    render: (h, params) => {
                      return h("div", [
                        h(
                          "Button",
                          {
                            props: {
                              type: "info",
                              size: "small",
                            },
                            on: {
                              click: () => {
                                this.tableModal= {
                                    tableStatus:true,
                                    modalStatus:true,
                                    tableData:[{},{}],
                                    talbeColumns:[],
                                    loading:false,
                                    page:1,
                                    pageSize:20,
                                    total:0
                                 }
                                 this.handle ={
                                    type:params.row.importType,
                                    archivesId:params.row.id
                                 } 
                                 if(params.row.importType == 5){
                                    this.tableModal.title="社区居民人员名册"
                                    this.tableModal.talbeColumns = this.orgTableColumns
                                    this.getOwnList(params.row.id)
                                 }else if(params.row.importType == 6){
                                    this.tableModal.title="网格(楼栋)党小组管理"
                                    this.tableModal.talbeColumns = this.gridBuildColumns
                                    this.getGridList(params.row.id)
                                 }else if(params.row.importType == 7){
                                    this.tableModal.title="网格党支部管理"
                                    this.tableModal.talbeColumns = this.gridTableColumns
                                    this.getPartyList(params.row.id)
                                 }
                                 
                              },
                            },
                          },
                          "查看明细"
                        ),
                        h('Button',{
                            props: {
                                type:params.row.isSync == '1' ? '' : 'error',
                                size: "small",
                                style: params.row.isSync == '1' ? 'none' : 'block'
                            },
                            on: {
                                click: () => {
                                    if(params.row.isSync == '1'){}
                                    else this.deleteListRow(params.row.id,params.row.orgCode)
                                    
                                }
                            },
                            style:{
                                marginLeft:'5px'
                            }
                        },params.row.isSync == '1' ? '已初始化' : '删除')
                      ]);
                    },
                  },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 20,
                currentPage:1
            },
            modal:{
                title:'导入',
                excelStatus:false,
                headers: {
                  Authorization:window.sessionStorage.getItem('accessToken')
                },
                showExcel:0,
                uploadData:{
                  orgCode: '',
               },
               action:'',
               orgCodeList:[],
               resetNum:0,
               modalStatus:false,
               showModal:{}
            },
            tableModal:{
                talbeColumns:[],
                tableData:[],
                loading:false,
                title:'',
                total:0,
                page:1,
                pageSize:20,
                tableStatus:false,
                modalStatus:false

            },
            // 社区居民人员名册表格数组
            orgTableColumns:[
                {
                    type:'index',
                    align:'center',
                    title:'序号',
                    minWidth:100
                },
                {
                    key:'houseName',
                    title:'门牌及房号',
                    align:'center',
                    minWidth:200
                },
                {
                    key:'beloneGrid',
                    title:'所属网格',
                    align:'center',
                    minWidth:150
                },
                {
                    key:'memberName',
                    title:'实际居住人员姓名',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'sex',
                    title:'性别',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'age',
                    title:'年龄',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'addr',
                    title:'户籍地址',
                    align:'center',
                    minWidth:300
                },
                {
                    key:'idNum',
                    title:'身份证号',
                    align:'center',
                    minWidth:300
                },
                {
                    key:'politicalOutlook',
                    title:'政治面貌',
                    align:'center',
                    minWidth:200
                },
                {
                    key:'twelve',
                    title:'是否本市居民',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'thirteen',
                    title:'是否来穗人员',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'fourteen',
                    title:'是否居住满半年以上',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'basePhone',
                    title:'联系电话',
                    align:'center',
                    minWidth:150
                },
                {
                    title: '重点人员情况',
                    align: 'center',
                    children: [
                        {
                            title: '残疾人员',
                            align: 'center',
                            key:'one',
                            minWidth:100
                        },
                        {
                            title: '卧床人员',
                            align: 'center',
                            key:'two',
                            minWidth:100
                        },
                        {
                            title: '慢性病患者（高血压、糖尿病等）',
                            align: 'center',
                            key:'three',
                            minWidth:100
                        },
                        {
                            title: '精神障碍患者',
                            align: 'center',
                            key:'four',
                            minWidth:100
                        },
                        {
                            title: '独居老人',
                            align: 'center',
                            key:"five",
                            minWidth:100
                        },
                        {
                            title: '孤寡老人',
                            align: 'center',
                            key:'six',
                            minWidth:100
                        },
                        {
                            title: '困境儿童',
                            align: 'center',
                            key:'seven',
                            minWidth:100
                        },
                        {
                            title: '孕产妇',
                            align: 'center',
                            key:'eight',
                            minWidth:100
                        },
                        {
                            title: '肾透析患者',
                            align: 'center',
                            key:'nine',
                            minWidth:100
                        },
                        {
                            title: '肿瘤化疗患者',
                            align: 'center',
                            key:'ten',
                            minWidth:100
                        },
                        {
                            title: '其他需按时就医患者',
                            align: 'center',
                            key:'eleven',
                            minWidth:100
                        },
                    ]
                },
                {
                    key:'remark',
                    title:'备注',
                    align:'center',
                    minWidth:100
                },
            ],
            // 网格党支部管理表格
            gridTableColumns:[
                {
                    key:'buildingName',
                    align:'center',
                    title:'网格（党支部）名称'
                },
                {
                    key:'manager',
                    align:'center',
                    title:'网格（党支部）负责人'
                },
                {
                    key:'managerMobile',
                    align:'center',
                    title:'网格（党支部）负责人手机号码'
                },
                {
                    key:'managerPolitical',
                    align:'center',
                    title:'负责人政治面貌'
                },
                {
                    key:'memberName',
                    align:'center',
                    title:'成员姓名'
                },
                {
                    key:'memberPhone',
                    align:'center',
                    title:'成员手机号'
                },
                {
                    key:'memberPolitical',
                    align:'center',
                    title:'成员政治面貌'
                },
                {
                    key:'jobName',
                    align:'center',
                    title:'工作单位（岗位）'
                },
                {
                    key:'remark',
                    align:'center',
                    title:'备注'
                },
            ],
            // 网格（楼栋）党小组管理
            gridBuildColumns:[
                {
                    key:'buildingName',
                    align:'center',
                    title:'网格(楼栋)党小组名称'
                },
                {
                    key:'gridName',
                    align:'center',
                    title:'所属网格党支部'
                },
                // {
                //     key:'registerTime',
                //     align:'center',
                //     title:'成立时间',
                //     render:(h,params)=>{
                //         return h ('div',{},this.$core.formatDate(new Date(params.row.registerTime),'yyyy-MM-dd hh:mm:ss'))
                //     }
                // },
                // {
                //     key:'partyNum',
                //     align:'center',
                //     title:'党员数量'
                // },
                {
                    key:'manager',
                    align:'center',
                    title:'网格楼栋组（党小组）负责人'
                },
                {
                    key:'managerMobile',
                    align:'center',
                    title:'网格楼栋组（党小组）负责人手机号码'
                },
                {
                    key:'managerPolitical',
                    align:'center',
                    title:'政治面貌'
                },
                {
                    key:'memberName',
                    align:'center',
                    title:'成员姓名'
                },
                {
                    key:'memberPhone',
                    align:'center',
                    title:'成员手机号'
                },
                {
                    key:'memberPolitical',
                    align:'center',
                    title:'成员政治面貌'
                },
                {
                    key:'jobName',
                    align:'center',
                    title:'工作单位（岗位）'
                },
                {
                    key:'remark',
                    align:'center',
                    title:'备注'
                },
            ],

            // 社区新居民人员名册表格
            orgNewTableColumns:[
                {
                    type:'index',
                    align:'center',
                    title:'序号',
                    minWidth:100
                },
                {
                    key:'houseName',
                    title:'门牌及房号',
                    align:'center',
                    minWidth:200
                },
                {
                    key:'memberNum',
                    title:'实际居住人数',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'beloneGrid',
                    title:'所属网格',
                    align:'center',
                    minWidth:150
                },
                {
                    key:'memberName',
                    title:'实际居住人员姓名',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'idNum',
                    title:'身份证号',
                    align:'center',
                    minWidth:300
                },
                // {
                //     key:'age',
                //     title:'年龄',
                //     align:'center',
                //     minWidth:100
                // },
                {
                    key:'city',
                    title:'是否本市居民',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'come',
                    title:'是否来穗人员',
                    align:'center',
                    minWidth:100
                },
                {
                    key:'basePhone',
                    title:'联系电话',
                    align:'center',
                    minWidth:150
                },
                {
                    title: '重点人员情况',
                    align: 'center',
                    children: [
                        {
                            title: '孕产妇',
                            align: 'center',
                            key:'alone',
                            minWidth:100
                        },
                        {
                            title: '肾透析患者',
                            align: 'center',
                            key:'difficulty',
                            minWidth:100
                        },
                        {
                            title: '肿瘤化疗患者',
                            align: 'center',
                            key:'retarded',
                            minWidth:100
                        },
                        {
                            title: '残疾或卧床人员',
                            align: 'center',
                            key:'ill',
                            minWidth:100
                        },
                        {
                            title: '慢性病患者（高血压、糖尿病患者等）',
                            align: 'center',
                            key:"foreign",
                            minWidth:100
                        },
                        {
                            title: '精神障碍患者',
                            align: 'center',
                            key:'else',
                            minWidth:100
                        },
                        {
                            title: '独居或高龄（80岁以上）老人',
                            align: 'center',
                            key:'sixty',
                            minWidth:100
                        },
                        {
                            title: '其他需按时就医患者',
                            align: 'center',
                            key:'party',
                            minWidth:100
                        },
                    ]
                },
                {
                    key:'remark',
                    title:'备注',
                    align:'center',
                    minWidth:100
                },
            ],

            handle:{
                type:'',
                archivesId:''
            }
        }
    },
    // 表格内的数据
    methods:{
        tableHadlePageSize(val){
            this.tableModal.page = val.page
            this.tableModal.pageSize = val.pageSize
            if(this.handle.type == '5'){
                this.getOwnList(this.handle.archivesId)
            }else if(this.handle.type == '6'){
                this.getGridList(this.handle.archivesId)
            }
            else if(this.handle.type == '7'){
                this.getPartyList(this.handle.archivesId)
            }
        },
        // 关闭分页事件
        tableModalData(status){
            this.tableModal.tableStatus = status
            if(!status) this.tableModal.modalStatus = false
        },

        // 获取社区动员体系首页分页接口
        getList(){
             this.table.loading = true
             this.$get('/syaa/pc/sy/user/gridManage/queryIndexByPage',{
                zoneCode:this.search.cascaderIdList.length == 1 ? this.search.cascaderIdList[this.search.cascaderIdList.length -1]  : '' ,
                streetCode:this.search.cascaderIdList.length == 2 ? this.search.cascaderIdList[this.search.cascaderIdList.length -1]  : '',
                page:this.table.page,
                pageSize:this.table.pageSize,
                orgCode: this.search.cascaderIdList.length == 3 ? this.search.cascaderIdList[this.search.cascaderIdList.length -1] : '',
                importType:this.search.importType == 1 ? '1,4' : this.search.importType,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode
            }).then(res=>{
                this.table.loading = false
                const { code ,dataList ,maxCount,currentPage} = res
                if(code && code == 200 && dataList && dataList.length){
                    this.table.tableData = dataList
                    this.table.total = maxCount
                    this.table.currentPage = currentPage

                }else{
                    this.table.tableData = []
                    this.table.total = 0
                }
            })
           

        },

        // 获取社区动员体系内页分页接口
        getOwnList(archivesId){
            this.tableModal.loading = true
            this.$get('/syaa/pc/sy/user/gridManage/queryResidentsByPage',{
                page:this.tableModal.page,
                pageSize:this.tableModal.pageSize,
                archivesId,
                importType:'1'
            }).then(res=>{
                this.tableModal.loading = false
                let { code ,dataList ,maxCount} = res
                if(code && code == 200 && dataList && dataList.length){
                    dataList.map(item =>{
                        if(item.userType && item.userType != ''){
                            item.userType = item.userType.split(',')
                            item.userType.map(items =>{
                                switch(items){
                                    case '1':
                                        item.one= '✔'
                                    break;
                                    case '2':
                                        item.two = '✔'
                                    break;
                                    case '3':
                                        item.three = '✔'
                                    break;
                                    case '4':
                                        item.four = '✔'
                                    break;
                                    case '5':
                                        item.five = '✔'
                                    break;
                                    case '6':
                                        item.six = '✔'
                                    break;
                                    case '7':
                                        item.seven = '✔'
                                    break;
                                    case '8':
                                        item.eight = '✔'
                                    break;
                                    case '9':
                                        item.nine = '✔'
                                    break;
                                    case '10':
                                        item.ten = '✔'
                                    break;
                                    case '11':
                                        item.eleven = '✔'
                                    break;
                                    case '12':
                                        item.twelve = '✔'
                                    break;
                                    case '13':
                                        item.thirteen = '✔'
                                    break;
                                    case '14':
                                        item.fourteen = '✔'
                                    break;
                                }
                            })
                        }
                    })
                    this.tableModal.tableData = dataList
                    this.tableModal.total = maxCount
                    
                }
            })
        },
        // 获取网格(楼栋)党小组管理
        getPartyList(archivesId){
            this.tableModal.loading = true
            this.$get('/syaa/pc/sy/user/gridManage/queryPartyManageByPage',{
                page:this.tableModal.page,
                pageSize:this.tableModal.pageSize,
                archivesId
            }).then(res=>{
                this.tableModal.loading = false
                let { code ,dataList ,maxCount} = res
                if(code && code == 200 && dataList && dataList.length){
                    this.tableModal.tableData = dataList
                    this.tableModal.total = maxCount
                    
                }
            })
        },
        // 获取网格
        getGridList(archivesId){
            this.tableModal.loading = true
            this.$get('/syaa/pc/sy/user/gridManage/queryGridGroupByPage',{
                page:this.tableModal.page,
                pageSize:this.tableModal.pageSize,
                archivesId
            }).then(res=>{
                this.tableModal.loading = false
                let { code ,dataList ,maxCount} = res
                if(code && code == 200 && dataList && dataList.length){
                    this.tableModal.tableData = dataList
                    this.tableModal.total = maxCount
                    
                }
            })
        },

        // 获取社区动员体系新模板接口
        getNewOwnList(archivesId){
            this.tableModal.loading = true
            this.$get('/syaa/pc/sy/user/gridManage/queryResidentsByPage',{
                page:this.tableModal.page,
                pageSize:this.tableModal.pageSize,
                archivesId,
                importType:'4'
            }).then(res=>{
                this.tableModal.loading = false
                let { code ,dataList ,maxCount} = res
                if(code && code == 200 && dataList && dataList.length){
                    dataList.map(item =>{
                        if(item.userType && item.userType != ''){
                            item.userType = item.userType.split(',')
                            item.userType.map(items =>{
                                switch(items){
                                    case '1':
                                        item.alone= '✔'
                                    break;
                                    case '2':
                                        item.difficulty = '✔'
                                    break;
                                    case '3':
                                        item.retarded = '✔'
                                    break;
                                    case '4':
                                        item.ill = '✔'
                                    break;
                                    case '5':
                                        item.foreign = '✔'
                                    break;
                                    case '6':
                                        item.else = '✔'
                                    break;
                                    case '7':
                                        item.sixty = '✔'
                                    break;
                                    case '8':
                                        item.party = '✔'
                                    break;
                                    case '9':
                                        item.city = '✔'
                                    break;
                                    case '10':
                                        item.come = '✔'
                                    break;
                                }
                            })
                        }
                    })
                    this.tableModal.tableData = dataList
                    this.tableModal.total = maxCount
                    
                }
            })
        },

        // 删除当前项数据
        deleteListRow(archivesId,orgCode){
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认删除当前信息？",
                onOk:() => {
                    this.$post('/syaa/pc/sy/user/gridManage/deleteByArchivesId',{
                        orgCode,
                        archivesId
                    }).then(res=>{
                        if(res.code == 200){
                            this.$Message.success({
                                content:'删除成功',
                                background:true
                            })
                            this.table.page = this.table.currentPage
                            this.getList()
                            return
                        }else{
                            this.$Message.error({
                                content:'删除失败',
                                background:true
                            })
                            return
                        }
                    })
                }
            })
        }
    }
}